header {
  display: flex;
  align-items: center;
  color: #fff;
  height: 85px;
}
.menu,
.wrapper__bot {
  display: flex;
  align-items: center;
}
.menu__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.sectionHome {
  background-image: url("../assets/img/png/Rectangle-6.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.section__inf {
  display: flex;
  justify-content: center;
}
.section__inf--text {
  text-align: center;

  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.section__inf--content {
  margin: 33px auto 0;
  max-width: 661px;
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.98px;
}
.btn__filter,
.cartes__inner > p,
.show__oll--cartes > p {
  font-weight: 600;
  line-height: normal;

  font-size: 16px;
  font-style: normal;
  text-align: center;
}
.section__filter {
  margin-top: 97px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.filter__inner {
  width: 95%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 86px;
  border-radius: 45px;
  border: 3px solid #bb9bea;
  background: #d5daf4;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.25) inset,
    0 -4px 8px 0 rgba(0, 0, 0, 0.25) inset;
}
.btn__filter {
  cursor: pointer;
  width: 100%;
  height: 86px;
  border: 0;
  background: initial;
  border-radius: 46px;
  color: #663db0;
}
.cartes__inner > p,
.show__oll--cartes {
  border-radius: 25px;
  display: flex;
  cursor: pointer;
}
.active {
  border-radius: 47px;
  background: #9f72e7;
  box-shadow: 0 16px 13px 2px #bc9eeb inset, 0 -7px 12px 2px #8357c9 inset,
    5px 0 11px -3px rgba(31, 30, 32, 0.25), -5px 0 11px -3px rgba(0, 0, 0, 0.25);
  color: #fff;
}
.carts__warpper,
.wrapper__inner {
  box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
}
.dropDownBnt {
  width: 100%;
  margin: 85px auto 20px;
}
.cursor-span,
.dropDownBnt__img {
  cursor: pointer;
}
.section__cartes {
  margin-top: 55px;
}
.cartes__inner > p {
  max-width: 384px;
  height: 58px;
  background: linear-gradient(180deg, #cb00e9 0, #7402ad 100%);
  margin: 0 auto;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.show__oll--cartes {
  width: 100%;
  margin: 0 auto 30px;
  flex-direction: row;
  color: #cb00e9;
  align-items: center;
  max-width: 385px;
  padding: 21px 16px;
  border: 2px solid #cb00e9;
  justify-content: space-between;
}
.show__oll--cartes > p {
  color: #cb00e9;
}
.wrapper__seorch--input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 19px 24px;
  border-radius: 25px;
  max-width: 381px;
  border: none;
}
.seorch__cart--input {
  border: 0;
  width: 100%;
  color: #afafaf;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.navigation__strel,
.show__oll--cats {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  font-style: normal;
}
.seorch__cart--input:focus {
  outline: 0;
}
.seorch__cart {
  display: flex;
  justify-content: center;
  gap: 21px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 48px;
  margin-bottom: 30px;
}
.selected {
  display: none;
}
.wrapper__chekBox {
  display: flex;
  gap: 51px;
}
.chekBox__inner {
  display: flex;
  align-items: center;
  color: #000;
  gap: 9px;
}
.navigation__strel {
  margin-top: 27px;
  margin-bottom: 26px;
  color: #fff;
}
.carts__warpper {
  max-width: 456px;
  border-radius: 25px;
  background: #fff;
  padding: 16px 27px 21px 31px;
}
.carts__warpper > img {
  cursor: pointer;
  width: 100%;
  height: auto;
}
.wrapper__showOll--carts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  max-width: 384px;
  height: 58px;
  margin: 30px auto;
  border: 2px solid #cb00e9;
  border-radius: 20px;
}
.show__oll--cats {
  color: #cb00e9;
  text-align: center;
}
.cart__context,
.name__Ai {
  text-align: left;
  color: #000;
}
.name__Ai {
  font-size: 32px;
  margin-top: 11px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.cart__bot,
.cart__context {
  font-weight: 400;
  line-height: normal;
}
.cart__context {
  display: block;
  max-width: 401px;

  font-size: 16px;
  font-style: normal;
}
.Ai__della,
.cart__bot {
  border-radius: 11px;
  background: #388f50;
  color: #fff;

  font-style: normal;
}
.cart__bot {
  padding: 7px 14px;
  font-size: 12px;
}
.bootom__cartGpt {
  display: flex;
  justify-content: center;
  color: #ae00d4;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.name__Promts {
  margin-top: 22px;
  color: #000;
  font-size: 24px;
}
.Ai__della {
  margin-top: 2px;
  display: flex;
  max-width: 130px;
  height: 29px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.link__on--Ai,
.wrapper__Ai-novigation > a {
  width: 233px;
  margin-bottom: 40px;
  height: 53px;
  border-radius: 53px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;

  font-style: normal;
  line-height: normal;
  text-align: center;
}
.wrapper__Ai-novigation {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: #fff;
  gap: 30px;
}
.wrapper__Ai-novigation > a {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: 0.6s;
  background: linear-gradient(to left, #8e2de2, #4a00e0, #8e2d32);
}
.wrapper__Ai-novigation > a:hover {
  background-position: -233px;
}
.link__on--Ai {
  cursor: pointer;
  background: rgb(58 31 141);
}
.education__top {
  display: flex;
  max-width: 1427px;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: center;
  margin: 0 auto;
}
.wrapper__education-text {
  display: flex;
  align-items: center;
  width: 50%;
}
.wrapper__education__img {
  width: 50%;
}
.wrapper__education-text > p {
  width: 100%;
  color: #c700e6;

  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wrapper__education__img > img {
  width: 100%;
  height: auto;
}
.wrapper__contact {
  display: flex;
  justify-content: center;
  margin-top: 44px;
  padding-left: 15px;
  padding-right: 15px;
}
.wrapper__inner {
  width: 1235px;
  background-color: rgb(201 126 245);
  background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
  background-position: left -575px center;
  background-repeat: no-repeat;
  display: flex;
  border-radius: 27px;
  padding-right: 200px;
}

.bacgraund__telegram--inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-image: url("../assets/img/svg/telegram__hend.svg");
  background-repeat: no-repeat;
  width: 277px;
  height: 290px;
}
.education__text {
  align-items: center;
  font-size: 32px;
  line-height: normal;

  font-style: normal;
  font-weight: 700;
  display: flex;
  max-width: 400px;
}
.education__text--end {
  align-items: center;
  font-size: 32px;
  line-height: normal;

  font-style: normal;
  font-weight: 700;
  display: flex;
}
.bacgraund__telegram--inner > img {
  max-width: 100%;
  height: auto;
  transform: translateY(10%);
}
.education__text {
  padding: 58px 44px 58px 40px;
  color: #c700e6;
}
.education__text--end {
  max-width: 397px;
  padding-left: 52px;
  color: #fff;
}
.wrapper__contact__left {
  position: relative;
  display: flex;
  align-items: center;
}
.wrapper__contact__left > p {
  position: relative;
  max-width: 534px;
  color: #c700e6;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 2;
}
.bacgraund__sorcl {
  height: 241px;
  border-radius: 10px;
}
.wrapper__contact__right {
  background: #000;
}
.wrapper__text--bottom {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  gap: 10px;
}
.contactes,
.footer__social {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer__public {
  padding-top: 40px;
  color: #787878;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 991px) {
  .menu__footer {
    display: none;
  }
  .menu__list {
    gap: 25px;
  }
  .wrapper__education-text > p {
    font-size: 35px;
  }
}
@media (max-width: 768px) {
  .menu-btn {
    display: flex;
    height: 10px;
  }
  .header__inner {
    position: absolute;
    top: 40px;
    right: 20px;
  }
  .section__filter {
    margin-top: 110px;
  }
  .burger,
  .logo {
    display: block;
  }
  .menu__list {
    display: none;
  }
  .filter__inner {
    display: contents;
    background: 0 0;
    justify-content: center;
    box-shadow: none;
    flex-wrap: wrap;
    gap: 12px;
    height: 0;
    border: 0;
  }
  .btn__filter {
    width: 45%;
    height: 60px;
    color: #fff;
    background: linear-gradient(
      180deg,
      rgba(87, 37, 166, 0.85) 0,
      rgba(15, 37, 133, 0.85) 100%
    );
    margin-inline: auto;
    margin-bottom: 20px;
  }
  .section__inf--content,
  .section__inf--text {
    color: #f3f3f3;
    text-align: center;

    font-style: normal;
  }
  .dropDownBnt {
    margin-top: 70px;
  }
  .wrapper__seorch--input {
    max-width: 360px;
    margin: 0 auto 4px;
  }
  .wrapper__chekBox {
    justify-content: center;
    margin-top: 7px;
    margin-right: 7px;
  }
  .wrapper__education-text > p {
    font-size: 30px;
  }
  .section__inf--text {
    font-size: 56px;
    font-weight: 700;
    line-height: normal;
  }
  .section__inf--content {
    font-size: 20px;
    font-weight: 400;
    line-height: 169.9%;
  }
  .footer__inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 560px) {
  .section__filter {
    margin-top: 87px;
  }
  .wrapper__seorch--input {
    max-width: 246px;
  }
  .show__oll--cartes {
    max-width: 174px;
  }
  .section__inf--text {
    font-size: 42px;
  }
}
@media (max-width: 376px) {
  .btn__filter,
  .section__inf--content,
  .section__inf--text {
    color: #f3f3f3;
    text-align: center;
  }
  .section__inner {
    margin-top: 134px;
  }
  .section__inf--text {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .section__inf--content {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 169.9%;
  }
  .btn__filter {
    height: 38px;

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-inline: auto;
    margin-bottom: 13px;
  }
  .cart__context,
  .name__Ai {
    color: #000;

    font-style: normal;
    line-height: normal;
  }
  .wrapper__seorch--input {
    max-width: 246px;
  }
  .show__oll--cartes {
    max-width: 174px;
  }
  .carts__warpper {
    max-width: 271px;
  }
  .name__Ai {
    font-size: 14px;
    font-weight: 700;
  }
  .cart__context {
    font-size: 9px;
    font-weight: 400;
  }
}
#salom {
  max-width: 100%;
  height: auto;
}
.container__hug {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 15px;
}
.neyronitem__wrap h2 {
  color: #000;

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.neyronitem__btns > span,
.neyronitem__wrap-item1 > p {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.neyroitem-section {
  padding: 55px 0 75px;
}
.neyronitem__wrap-item {
  display: flex;
  justify-content: center;
  gap: 70px;
  margin-bottom: 60px;
}
.neyronitem__btns,
.neyronitem__footer-btns {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.neyronitem__wrap-item1 {
  max-width: 610px;
}
.neyronitem__wrap-item1 > img {
  width: 100%;
  height: 335px;
  margin-bottom: 20px;
}
.neyronitem__btns {
  align-items: center;
  margin-bottom: 28px;
}
.neyronitem__btns > span {
  cursor: pointer;
  padding: 7px 10px;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  background: rgba(31, 129, 58, 0.89);
}
.neyronitem__wrap-item1 > p {
  color: #000 !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 28px;
}
.neyronitem__footer-btns > img {
  width: 42px;
  height: 40px;
}
.neyronitem__footer-btns > button,
.neyronitem__footer-btns > a {
  display: block;
  width: 384px;
  height: 58px;
  border-radius: 25px;
  background: linear-gradient(180deg, #cb00e9 0, #7402ad 100%);
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.neyron-item__masange > h3,
.neyron-item__nerons {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
  text-align: center;
}
.neyronitem__reklamam {
  overflow: hidden;
  margin: 20px;
  width: 413px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
}
.neyron-item__masange {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 60px;
}
.neyron-item__masange-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.neyron-item__nerons {
  margin-bottom: 25px;
}
.neyron-item__inner-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}
.wrapper__bot {
  margin-top: 10px;
  gap: 5px;
}
.generator-seciton,
.midjourney {
  padding: 60px 0;
}
.genarator__wrap {
  margin-bottom: 40px;
}
.genarator__wrap > h2 {
  color: #000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}
.drugiyi__promp > h4,
.gener-iner__iner > h3 {
  color: #000;
  font-size: 24px;
  line-height: normal;
  font-style: normal;
  text-align: center;
}
.generator__wrap-inner {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}
.gener-iner__iner > h3 {
  font-weight: 700;
  margin-bottom: 12px;
}
.gener-iner__text {
  padding: 20px;
  max-width: 610px;
  height: auto;
  border-radius: 24px;
  background: #fff;
  box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 22px;
}
.gener-iner__copy {
  display: block;
  width: 242px;
  height: 55px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 17px;
  border-radius: 22px;
  background: #fff;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.25);
}
.midjourney__black,
.midjourney__white {
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 10px;
}
.drugiyi__promp > h4 {
  font-weight: 600;
  margin-bottom: 30px;
}
.midjourney__white {
  background: #fff;
}
.midjourney__black {
  background: #362f2d;
}
.carts__warpper,
.name__Promts {
  margin-bottom: 20px;
}
.wrapper__oll--cartes {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}
.carts__warpper {
  width: 401px;
  height: 456px;
}
.cart__context {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical;
}
.carts__warpper3 {
  width: 28%;
  padding: 20px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
}
.carts__warpper3 > img {
  width: 100%;
  height: 228px;
}
@media only screen and (max-width: 840px) {
  .seorch__cart {
    flex-wrap: wrap;
    padding: 0;
  }
}
@media only screen and (max-width: 520px) {
  .cart__context2,
  .name__Ai,
  .name__Promts {
    font-style: normal;
    line-height: normal;
    color: #000;
  }
  .carts__warpper {
    width: 271px;
    height: 271px;
    margin-bottom: 15px;
  }
  .name__Ai {
    font-size: 14px;
    font-weight: 700;
  }
  .name__Promts {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .cart__bot,
  .cart__context,
  .cart__context2 {
    font-size: 9px;
    font-weight: 400;
  }
  .bootom__cartGpt {
    cursor: pointer;
  }
  .cart__context2 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 16 !important;
    -webkit-box-orient: vertical;
  }
  .cart__context {
    color: #000;

    font-style: normal;
    line-height: normal;
  }
  .cart__bot,
  .cartes__inner > p,
  .name__Page {
    color: #fff;
    text-align: center;

    font-style: normal;
    line-height: normal;
  }
  .wrapper__bot > img {
    width: 18px;
    height: 18px;
  }
  .cartes__inner > p {
    max-width: 246px;
    font-size: 10px;
    font-weight: 600;
  }
  .footer__public {
    padding-top: 10px;
  }
  .name__Page {
    font-size: 32px;
    font-weight: 700;
  }
}
.hamburger,
.mobile__logo,
.neyron-item__masangerek {
  display: none;
}
.bootom__cartGpt {
  margin-top: 25px;
  cursor: pointer;
}
@media only screen and (max-width: 950px) {
  .neyronitem__reklamam {
    display: none;
  }
  .neyron-item__masangerek {
    overflow: hidden;
    display: block;
    width: 90%;
    border-radius: 15px;
    background: #fff;
    padding: 0;
    color: #000;
    text-align: center;

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 235px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .neyronitem__wrap-item {
    justify-content: center;
  }
  .neyronitem__wrap {
    text-align: center;
  }
}
@media only screen and (max-width: 420px) {
  .neyronitem__wrap h2 {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
  }
  .drugiyi__promp > h4,
  .neyron-item__masange > h3,
  .neyron-item__nerons {
    color: #000;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .neyronitem__wrap-item1 > img {
    height: 176px;
    border: 10px;
  }
}
@media only screen and (max-width: 820px) {
  .genarator__wrap > h2 {
    font-size: 32px;
    line-height: 38px;
  }
  .generator__wrap-inner {
    flex-direction: column;
  }
  .gener-iner__text {
    margin: 20px auto;
  }
}
@media only screen and (max-width: 1000px) {
  .education__text {
    max-width: 300px;
    font-size: 28px;
    padding-right: 20px;
    padding-left: 15px;
  }
  .education__text--end {
    font-size: 28px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 895px) {
  .education__text {
    max-width: 200px;
    font-size: 22px;
    padding-left: 15px;
    padding-right: 100px;
  }
  .education__text--end {
    font-size: 22px;
    padding-left: 10px;
  }
  .wrapper__inner {
    max-width: 1427px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -341px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 27px;
    box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  }
}
@media only screen and (max-width: 773px) {
  .bacgraund__telegram--inner > img {
    width: 50px;
    height: 50px;
  }
  .bacgraund__telegram--inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-image: url(/src/assets/img/svg/telegram__hend.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 763px) {
  .wrapper__inner {
    width: 100%;
    max-width: 1427px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -241px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 27px;
    box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  }
  .education__text {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 595px) {
  .education__text,
  .education__text--end {
    font-size: 18px;
    padding-left: 10px;
  }
  .wrapper__inner {
    max-width: 1427px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -190px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 20px;
    box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  }
  .education__text {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 510px) {
  .bacgraund__telegram--inner > img {
    width: 40px;
    height: 40px;
  }
  .wrapper__inner {
    max-width: 1427px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -155px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 15px;
    box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  }
  .bacgraund__telegram--inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-image: url(/src/assets/img/svg/telegram__hend.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
  }
  .education__text {
    max-width: 190px;
    font-size: 12px;
    padding: 25px 25px 25px 10px;
  }
  .education__text--end {
    font-size: 12px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 390px) {
  .education__text,
  .education__text--end {
    font-size: 10px;
    padding-left: 10px;
  }
  .wrapper__inner {
    max-width: 1427px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -135px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 10px;
    box-shadow: 7px 7px 11px 0 rgba(0, 0, 0, 0.15);
  }
  .education__text {
    max-width: 190px;
    padding-right: 20px;
  }
}
.wrapper__education {
  padding: 40px 0;
  height: auto;
  display: flex;
  align-items: center;
  background: linear-gradient(
    360deg,
    #b9a1ff 0,
    rgba(253, 251, 255, 0.79) 45.41%,
    #f2cdff 100%
  );
}
.wrapper_content--education {
  max-width: 90%;
  margin: 20px auto;
  border-radius: 20px;
  background: #fff;
  padding: 20px;
}
.wrapper_content--education > h1 {
  color: #000;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}
.wrapper_content--education > p {
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media only screen and (max-width: 800px) {
  .wrapper__education-text > p {
    font-size: 28px;
  }
}
@media only screen and (max-width: 660px) {
  .wrapper__education-text > p {
    font-size: 22px;
  }
}
@media only screen and (max-width: 480px) {
  .wrapper__education {
    flex-direction: column;
  }
  .education__top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wrapper__education-text {
    width: 80%;
    text-align: center;
  }
  .wrapper__education__img {
    width: 80%;
    margin: 10px auto;
  }
  .wrapper__education-text > p {
    font-size: 18px;
  }
}
.menu-btn__burger,
.menu-btn__burger::after,
.menu-btn__burger::before {
  width: 35px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: 0.5s ease-in-out;
}
@media only screen and (max-width: 540px) {
  .neyronitem__footer-btns > button,
  .neyronitem__wrap-item1 > p {
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }
  .neyroitem-section {
    padding: 35px 0;
  }
  .neyronitem__footer-btns > button,
  .neyronitem__footer-btns > a {
    color: #fff;
    font-weight: 700;
    width: 60%;
    height: 40px;
  }
  .neyronitem__footer-btns > img {
    width: 40px;
    height: 40px;
  }
  .neyron-item__masange-wrap img {
    width: 126.149px;
    height: 40px;
  }
  .neyronitem__wrap-item {
    padding: 0 10px;
  }
  .neyronitem__wrap-item1 > p {
    color: #000;
    font-weight: 400;
  }
  .genarator__wrap > h2,
  .gener-iner__iner > h3,
  .gener-iner__text,
  .wrapper_content--education > h1,
  .wrapper_content--education > p {
    color: #000;

    font-style: normal;
    line-height: normal;
  }
  .genarator__wrap > h2,
  .gener-iner__iner > h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
  .gener-iner__text {
    font-size: 12px;
    font-weight: 400;
  }
  .carts__warpper3 {
    width: 271px;
  }
  .wrapper_content--education > h1 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .wrapper_content--education > p {
    font-size: 12px;
    font-weight: 300;
  }
}
@media only screen and (max-width: 400px) {
  .wrapper_content--education > p {
    color: #000;

    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.midjourney__black,
.midjourney__white,
.midjourney__white > a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.menu-btn__burger::after,
.menu-btn__burger::before {
  content: "";
  position: absolute;
  height: 4 px;
}
.menu-btn__burger {
  height: 4px;
}
.menu-btn {
  position: relative;
  top: -10px;
  display: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 34px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.hamburger__div {
  position: absolute;
  top: 65px;
  padding: 20px 0;
  width: 100%;
  z-index: 4;
  background-color: #362f2d;
}
.moblik {
  display: block;
  text-align: center;
  padding: 10px;
}
@media only screen and (max-width: 769px) {
  header {
    height: 60px;
  }
  .menu-btn {
    display: block;
  }
  .mobile__logo {
    width: 100%;
    height: 29px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  .mobile__logo img {
    width: 169px;
    height: 29px;
  }
  .hamburger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 22px;
    right: 20px;
  }
  .hamburger > img {
    width: 18px;
  }
  .header__inner {
    display: none;
  }
}
.midjourney__white {
  color: #000;
  line-height: normal;
}
.midjourney__black {
  color: #fff;
  line-height: normal;
}
.midjourney__white > a {
  color: #000;
  line-height: normal;
  text-decoration-line: underline;
}
.midjourney__black > span,
.midjourney__white > span,
.paginate > li {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.midjourney__white > span {
  color: #000;
  font-size: 16px;
}
.midjourney__black > span {
  color: #fff;
  font-size: 16px;
}
.trans {
  transform: rotate(180deg);
  transition: 0.5s;
}
.mojourney__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.midjourney__img2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.midjourney__img2 > img {
  width: 348px;
  height: 348px;
}
.midjourney__strukimg,
.midjoypramimg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.midjourney__strukimg > img {
  width: 621px;
  height: 348px;
}
.midjoypramimg > img {
  width: 576px;
  height: 337px;
}
.active-span {
  cursor: pointer;
  color: #c700e6;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.midjourney__img6 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}
.midjourney__img6 > img {
  width: 348px;
  height: 348px;
}
.container__hug2 {
  width: 90%;
  margin: 0 auto;
}
@media only screen and (max-width: 1194px) {
  .midjourney__img6 > img {
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 1025px) {
  .midjourney__img6 > img {
    width: 250px;
    height: 250px;
  }
}
@media only screen and (max-width: 860px) {
  .midjourney__img6 > img {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 696px) {
  .midjourney__img6 {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .midjourney__img6 > img {
    width: 348px;
    height: 348px;
  }
}
.dropDownBnt,
.paginate {
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 460px) {
  .midjourney__img6 > img {
    width: 312px;
    height: 312px;
  }
  .midjourney__img6 {
    gap: 10px;
  }
}
@media only screen and (max-width: 687px) {
  .midjourney__strukimg > img {
    width: 100%;
    height: 348px;
  }
}
@media only screen and (max-width: 393px) {
  .midjourney__img2 > img {
    width: 247px;
    height: 246px;
  }
}
@media only screen and (max-width: 968px) {
  .mojourney__img > img {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .carts__warpper3 > img {
    width: 100%;
    height: 170px;
  }
  .midjourney__black,
  .midjourney__black > span,
  .midjourney__white,
  .midjourney__white > a,
  .midjourney__white > span {
    font-size: 12px;
  }
  .midjoypramimg > img {
    width: 100%;
    height: 270px;
  }
  .midjourney__strukimg > img {
    height: 173px;
  }
}
@media only screen and (max-width: 430px) {
  .midjoypramimg > img {
    height: 170px;
  }
}
.paginate {
  justify-content: center;
  gap: 8px;
  margin-bottom: 40px;
}
.paginate > li {
  padding: 10px;
  background-color: #fff;
  color: #8e01bf;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
}
.active-paginate {
  border: 1px solid #8e01bf;
}
.wrapper__oll--cartes {
  width: 90%;
  margin: 40px auto;
  margin-top: 90px;
}
@media only screen and (max-width: 1150px) {
  .carts__warpper3 {
    width: 40%;
  }
}
@media only screen and (max-width: 810px) {
  .carts__warpper3 {
    width: 80%;
  }
  .carts__warpper3 > img {
    height: 270px;
  }
}
.midjourney h3 {
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 20px 10px;
}
.pagination__mid > button,
.pagination__mid > span {
  color: #8e01bf;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: #fff;
}
.dropDownBnt {
  justify-content: center;
  cursor: pointer;
}
.section__inner {
  margin-top: 96px;
  text-align: center;
  color: #fff;
}
@media only screen and (max-width: 830px) {
  .section__inner {
    margin-top: 50px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (max-width: 768px) {
  .section__filter {
    margin-top: 75px;
  }
  .dropDownBnt {
    margin-top: 45px;
  }
}
@media only screen and (max-width: 521px) {
  .carts__warpper3 > img {
    height: 170px;
  }
  .page__Promty {
    height: 210px;
  }
  .navigation__strel {
    margin-bottom: 13px;
  }
}
.neyro__reklamaimg {
  width: 100%;
  height: 100%;
}
.pagination__mid {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 20px;
}
.pagination__mid > span {
  padding: 8px 10px;
}
.pagination__mid > button {
  display: block;
  height: 35px;
  cursor: pointer;
}
.pagination__mid > button > img {
  width: 26px;
}
.hee {
  color: #000;
}
.heightt {
  display: block;
  height: 200px;
  width: 100%;
}

.wrapper__oll--cartes {
  width: 98%;
}
.container__lil {
  max-width: 1500px;
  padding: 0 30px;
  margin: 0 auto;
}
.container__mid {
  max-width: 1160px;
  padding: 0 30px;
  margin: 0 auto;
}
.sectionHome {
  height: auto;
  padding-bottom: 210px;
}
.wrapper__education {
  height: 78vh;
}

@media only screen and (max-width: 1300px) {
  .sectionHome {
    height: auto;
  }
  .wrapper__education {
    height: auto;
  }
}
.sectionHome {
  position: relative;
}
.dropDownBnt {
  position: absolute;
  bottom: 0px;
}
@media only screen and (max-width: 1300px) {
  .sectionHome {
    height: auto;
    padding-bottom: 120px;
  }
}
.wrapper__bot {
  flex-wrap: wrap;
}
.reklama__homeimg {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.reklama__main {
  height: 458px;
  width: 438px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
}
.reklama__main2 {
  height: 456px;
  width: 438px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
}
.reklama__main-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: #000;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.carts__warpper4 {
  width: 438px;
  overflow: hidden;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
}
.wrapper__oll--cartes {
  justify-content: center;
}
.carts__wrapper5 {
  width: 398px !important;
  padding: 20px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
}
.carts__wrapper5 > img {
  width: 398px;
  height: 205px;
}
@media only screen and (max-width: 1432px) {
  .carts__wrapper5 {
    padding: 15px;
    border-radius: 15px;
    width: 358px !important;
  }
  .carts__wrapper5 > img {
    width: 350px;
  }
  .carts__warpper4 {
    width: 388px;
    border-radius: 15px;
  }
  .reklama__main {
    height: 458px;
    width: 388px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .reklama__main2 {
    height: 456px;
    width: 388px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
}
@media only screen and (max-width: 1300px) {
  .carts__wrapper5 {
    padding: 15px;
    border-radius: 15px;
    width: 318px !important;
  }
  .carts__wrapper5 > img {
    width: 310px;
  }
  .carts__warpper4 {
    width: 348px;
    border-radius: 15px;
  }
  .reklama__main {
    height: 458px;
    width: 348px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .reklama__main2 {
    height: 456px;
    width: 348px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
}
@media only screen and (max-width: 1166px) {
  .wrapper__oll--cartes {
    gap: 40px !important;
  }
}

@media only screen and (max-width: 811px) {
  .reklama__main {
    height: 448px;
    width: 348px;
  }
  .reklama__main2 {
    height: 448px;
    width: 348px;
  }
}
@media only screen and (max-width: 521px) {
  .carts__wrapper5 > img {
    width: 100%;
    height: 121.831px;
  }
  .reklama__main {
    height: 269px;
    width: 291px;
  }
  .reklama__main2 {
    height: 269px;
    width: 291px;
  }
  .carts__wrapper5 {
    padding: 10px;
    border-radius: 15px;
    width: 271px !important;
  }
}
.addnewreklam {
  max-width: 1152px;
  height: 194.556px;
  border-radius: 23px;
  background: #fff;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  overflow: hidden;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addnewreklam > h2 {
  color: #000;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media only screen and (max-width: 500px) {
  .addnewreklam {
    height: 106px;
    border-radius: 15px;
    box-shadow: 0px 0px;
  }
  .addnewreklam > h2 {
    font-size: 12px;
  }
}
.drugiyi__promp {
  margin-top: 52px;
}
.section__filter2 {
  margin-top: 41px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.page__Promty {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: calc(50% - 30px);
  height: 445px;
}
.page__Promty--One {
  background-position: calc(50% - 30px);
}
@media only screen and (max-width: 769px) {
  .page__Promty {
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
  }
}
@media only screen and (max-width: 521px) {
  .page__Promty {
    background-size: cover;
    background-repeat: no-repeat;
    height: 480px;
  }
}
@media only screen and (max-width: 377px) {
  .page__Promty {
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
  }
}
.page__reklama {
  height: 290px;
  width: 100%;
  background-image: url("../assets/img/png/Rectangle\ mini.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: calc(50% - 30px);
}

@media only screen and (max-width: 769px) {
  .page__reklama {
    height: 250px;
  }
}
@media only screen and (max-width: 521px) {
  .page__reklama {
    height: 210px;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper__oll--cartes {
    gap: 18px !important;
  }
}
.reklama__main2,
.reklama__main {
  overflow: hidden;
}
.dropDownBnt {
  animation: animatsiya 1s ease-in-out infinite alternate;
}
@keyframes animatsiya {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(15px);
  }
}
.scrollinghead {
  position: fixed !important;
  width: 100%;
  top: 0;
  background-color: #362f2d;
  z-index: 900;
}
.name__Promts {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}
.cart__context3 {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 13 !important;
  -webkit-box-orient: vertical;
}
.cart__context2 {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}
.cart__context {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}
.slect-imgrotates > img {
  transform: rotate(-180deg) !important;
  transition: all 0.3s;
}
.wrapper__inner {
  position: relative;
}
.bacgraund__telegram--inner2 {
  position: absolute;
  right: 20px;
  top: -15px;
}
@media only screen and (max-width: 1390px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -466px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 27px;
    padding-right: 200px;
  }
}
@media only screen and (max-width: 1322px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -466px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 27px;
    padding-right: 200px;
  }

  .education__text--end {
    padding-left: 40px;
    max-width: 350px;
  }

  .education__text {
    max-width: 360px;
    padding: 58px 44px 58px 40px;
    color: #c700e6;
  }
}

@media only screen and (max-width: 1241px) {
  .education__text,
  .education__text--end {
    font-size: 25px;
  }
}
@media only screen and (max-width: 1198px) {
  .education__text--end {
    padding-left: 0px;
    max-width: 350px;
  }
}
@media only screen and (max-width: 1156px) {
  .education__text {
    max-width: 300px;
  }
}
@media only screen and (max-width: 1085px) {
  .education__text {
    max-width: 300px;
  }
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -406px center;
    background-repeat: no-repeat;
    display: flex;
  }
}
@media only screen and (max-width: 1023px) {
  .bacgraund__telegram--inner2 {
    top: -5px;
  }
  .bacgraund__telegram--inner2 > img {
    width: 200px;
  }
  .education__text {
    padding: 30px;
  }
}

@media only screen and (max-width: 979px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -356px center;
    background-repeat: no-repeat;
    display: flex;
  }
}
@media only screen and (max-width: 909px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -306px center;
    background-repeat: no-repeat;
    display: flex;
  }
}
@media only screen and (max-width: 839px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -276px center;
    background-repeat: no-repeat;
    display: flex;
  }
}
@media only screen and (max-width: 785px) {
  .education__text,
  .education__text--end {
    font-size: 20px;
  }
}

@media only screen and (max-width: 718px) {
  .education__text,
  .education__text--end {
    font-size: 20px;
  }
}
@media only screen and (max-width: 661px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -216px center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 601px) {
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -216px center;
    background-repeat: no-repeat;
    display: flex;
    padding-right: 180px;
  }
  .education__text,
  .education__text--end {
    font-size: 15px;
  }
  .bacgraund__telegram--inner2 > img {
    width: 150px;
  }
}
@media only screen and (max-width: 562px) {
  .education__text,
  .education__text--end {
    padding: 20px 10px;
    font-size: 11px;
  }
  .education__text,
  .education__text--end {
    font-size: 14px;
  }
}
@media only screen and (max-width: 526px) {
  .education__text,
  .education__text--end {
    font-size: 13px;
  }
  .education__text,
  .education__text--end {
    padding: 20px 10px;
    font-size: 11px;
  }
  .wrapper__inner {
    width: 80%;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -186px center;
    background-repeat: no-repeat;
    display: flex;
    padding-right: 160px;
  }
}

@media only screen and (max-width: 470px) {
  .wrapper__inner {
    padding-right: 120px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -166px center;
    background-repeat: no-repeat;
    display: flex;
    height: 68px;
  }
  .education__text,
  .education__text--end {
    padding: 10px;
    border-radius: 5px;
    font-size: 9px;
  }
  .bacgraund__telegram--inner2 > img {
    width: 100px;
  }
}
@media only screen and (max-width: 420px) {
  .wrapper__inner {
    padding-right: 120px;
    background-color: rgb(201 126 245);
    background-image: radial-gradient(circle, #fff 50%, rgb(201 126 245) 50%);
    background-position: left -150px center;
    background-repeat: no-repeat;
    display: flex;
  }
}
.newRekalamm {
  width: 100%;
  height: 100%;
  padding: 3px;
  overflow-y: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.newbarnerreklama {
  overflow: hidden;
  margin-top: 20px;
  padding: 10px;
}
@media only screen and (max-width: 500px) {
  .wrapper__oll--cartes {
    margin-top: 40px;
  }
}
.img169 {
  height: 348px !important;
  width: 621px !important;
}
@media only screen and (max-width: 720px) {
  .img169 {
    width: 95% !important;
  }
}
@media only screen and (max-width: 470px) {
  .img169 {
    height: 173px !important;
  }
  .wrapper__chekBox {
    gap: 15px;
  }
  .cheked {
    width: 20px;
    height: 20px;
  }
}

.active-page {
  background-color: #7402ad !important;
  color: white !important;
}
.pagination__mid {
  flex-wrap: wrap;
}
.pagination__mid > a {
  cursor: pointer;
}
.pagination__mid > a {
  display: block;
  padding: 7px 10px;
  color: #8e01bf;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: #fff;
}
.carts__wrapper5 {
  cursor: pointer !important;
}
.cart__context3 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.name__Ai {
  color: #000;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (max-width: 530px) {
  .name__Ai {
    font-size: 18px !important;
  }
}
