/* montserrat-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('./Fonts/montserrat-v26-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-300.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }
  /* montserrat-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('./Fonts/montserrat-v26-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }
  /* montserrat-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./Fonts/montserrat-v26-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('./Fonts/montserrat-v26-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('./Fonts/montserrat-v26-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }
  /* montserrat-800 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('./Fonts/montserrat-v26-latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
         url('./Fonts/montserrat-v26-latin-800.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  }

 body{
    font-family:Arial , sans-serif  !important;    
 }

 .image-container {
     position: relative;
     display: inline-block;
 }
 
 .image-container img {

     height: auto;
     transition: 0.3s; /* Hover effekti uchun tranzitsiya */
 }
 
 .image-container .hover-text {
     position: absolute;     
     top: -38px;
     left: 0px;
     opacity: 0;
     background-color: blueviolet; /* Orqa fon rangi va shaffoflik */
     color: #fff; /* Yozuv rangi */
     z-index: 5;
     transition: 0.3s;
     padding: 6px 10px;
     border-radius: 8px;
     display: flex;
     font-size: 12px;   
     align-items: center;
     justify-content: center;
     height: 25px ;
 }
 
 .image-container:hover img {
     opacity: 0.7; /* Rasmni biror qancha o'zgartirish uchun */
 }
 
 .image-container:hover .hover-text {
     opacity: 1; /* Hover bo'lganda yozuvni ko'rsatish */
 }
    .gener-iner__text > p{
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
    }